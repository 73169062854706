import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "@stylesPages/Terms.scss";

import Header from "@components/Header";
import Footer from "@components/Footer";
import Hero from "@components/Hero";
import ScrollTabs from "@components/ScrollTabs";

export default function Terms() {
  const { t } = useTranslation("terms");

  return (
    <>
      <Header />
      <main className="terms">
        <Hero title={t("title")} />
        <ScrollTabs
          tabs={[
            {
              id: 0,
              title: "execution_policy",
              content: "execution_policy-content"
            },
            {
              id: 1,
              title: "inactivity",
              content: "inactivity-content"
            },
            {
              id: 2,
              title: "privacy_policy",
              content: "privacy_policy-content"
            },
            {
              id: 3,
              title: "risk_disclosure",
              content: "risk_disclosure-content"
            },
            {
              id: 4,
              title: "conflict_policy",
              content: "conflict_policy-content"
            },
            {
              id: 5,
              title: "aml",
              content: "aml-content"
            },
            {
              id: 6,
              title: "kyc",
              content: "kyc-content"
            },
            {
              id: 7,
              title: "bono",
              content: "bono-content"
            }
          ]}
          translation="terms"
        />
      </main>
      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
